/*
 * @Author: nice
 * @Date: 2021-11-27 21:28:54
 * @LastEditTime: 2021-12-07 14:07:44
 * @LastEditors: nice
 * @Description: 请求路径配置
 */
// 请求ip
export const httpIp = BASE_URL;
// export const httpIp = 'http://1x';

// 缴费、抄表
export const jfHttpUrl = `${httpIp}/smart-revenue-charge`;
// export const jfHttpUrl = `${httpIp}:8083/smart-revenue-charge`;

// 域名
export const domainUrl = DOMAIN_URL;
// export const domainUrl = 'http://swdev.trydotec.com';

// 公众号appid
export const appid = APP_ID;
// export const appid = 'wx359388e842a86882';

/**
 * 获取微信授权code
 * @param {Object} parames 前端自定义参数
 * @param {String} payUrl 前端拉起支付的页面地址
 */
export const goToPayUrlWithWxCode = (parames) => {
  const paramesStr = JSON.stringify(parames) || '';
  const payUrl = encodeURIComponent(`${domainUrl}/payAtOnce`); // 回调地址需要urlcode转码
  const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${payUrl}&response_type=code&scope=snsapi_base&state=${encodeURIComponent(
    paramesStr
  )}#wechat_redirect`;
  // 本地测试（下面地址的格式是微信返回的格式,取得code后将其拼接在地址上，替换xxxx-xxxx-xxx）
  // const authUrl = `/payAtOnce?code=xxxx-xxxx-xxx&state=${encodeURIComponent(paramesStr)}#wechat_redirect`;
  window.location.href = authUrl;
};
