/*
 * @Author: nice
 * @Date: 2021-11-21 20:30:55
 * @LastEditTime: 2021-12-14 20:16:24
 * @LastEditors: nice
 * @Description: 缴费
 */
'use strict';
import http from './http.js';
import { jfHttpUrl as httpUrl } from './httpUrlConfig';

/**
 * [通过手机号/身份证/用户号查询用户]
 */
export const getCustomerNumber4AppVo = data => {
  return http(`${httpUrl}/oapi/getCustomerNumber4AppVo`, {
    method: 'get',
    data
  });
};

/**
 * [支付订单]
 */
export const payOrder = data => {
  return http(`${httpUrl}/oapi/pay`, {
    method: 'post',
    data
  });
};

/**
 * [生成支付订单]
 */
export const createPayOrder = data => {
  return http(`${httpUrl}/oapi/createPayOrder`, {
    method: 'post',
    data
  });
};

/**
 * [生成表端/账户充值订单]
 */
export const createRechargeOrder = data => {
  return http(`${httpUrl}/oapi/createRechargeOrder`, {
    method: 'post',
    data
  });
};

/**
 * [4/21更新获取支付记录]
 */
export const getPayRecord = data => {
  return http(`${httpUrl}/oapi/getOrderList4App`, {
    method: 'post',
    data
  });
};

/**
 * [通过用户号查询未支付账单和账单明细]
 */
export const getAccountOrderByCustomerNumberId = data => {
  return http(`${httpUrl}/oapi/getAccountOrderByCustomerNumberId`, {
    method: 'post',
    data
  });
}

/**
 * [根据用户号获取欠费总额]
 */
export const getTotalAmountByCustomerNumber = data => {
  return http(`${httpUrl}/oapi/getTotalAmountByCustomerNumber`, {
    method: 'get',
    data
  });
};
/**
 * [获取公司名
 */
export const getCompanyName = data => {
  return http(`${httpUrl}/systemConfig/sysConfig/getCompanyName`, {
    method: 'get',
    data
  });
};
/**
 * 获取微信公众号平台状态 9200启用，非9200：停用
 * @returns {{cancel: *, promise: Promise<unknown>}|Promise<unknown>|{cancel: *, promise: Promise<unknown>}}
 */
export const getPlatformStatus = data => {
  return http(`${httpUrl}/oapi/checkAccountsDate`, {
    method: 'get',
    data
  });
};
