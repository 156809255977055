/*
 * @Author: master
 * @Date: 2021-11-01 14:20:38
 * @LastEditTime: 2021-12-02 18:56:34
 * @Description: http请求
 */
'use strict';
import axios from 'axios';
import { getLocalStorage } from '@/common/localDataStorage.js';
import { Toast, Notify } from 'vant';
import { userLogout } from '@/common/globalServer.js';
axios.defaults.timeout = 30000;
// request 拦截器
axios.interceptors.request.use(
  config => config,
  error => {
    return Promise.reject(error);
  }
);

// response 拦截器(响应拦截器即异常处理)
axios.interceptors.response.use(
  response => response,
  error => {
    /**
     * 超时处理
     */
    if (error?.message?.includes('timeout')) {
      Toast.fail('请求超时');
    } else if (error?.message?.includes('Network')) {
      Toast.fail('网络出现异常');
    }
    if (error?.response?.data?.message) {
      Notify(error.response.data.message);
    }
    if (error?.response?.status === 401) {
      return userLogout();
    }
    return Promise.reject(error);
  }
);
/**
 * get
 * @param url
 * @param option
 */
const get = (url, options) => {
  const promise = new Promise((resolve, reject) => {
    axios.get(url, options).then(
      response => {
        resolve(response.data);
      },
      err => {
        if (err) {
          console.error('err', err);
          reject(err);
        }
      }
    );
  });
  if (options.isCancel) {
    return { promise: promise, cancel: options.cancel };
  }
  return promise;
};
/**
 * 数据操作类型（post、put、delete、patch）
 * @param url
 * @param options
 */
const optionMethod = (url, options) => {
  let httpType = 'post'; // 请求类型
  switch (options.method) {
    case 'post':
    case 'POST':
      httpType = 'post';
      break;
    case 'patch':
    case 'PATCH':
      httpType = 'patch';
      break;
    case 'put':
    case 'PUT':
      httpType = 'put';
      break;
    case 'DELETE':
    case 'delete':
      httpType = 'delete';
      break;
    default:
      httpType = 'post';
  }
  const body = setOptionMethodBody(options, httpType); // 转换body数据格式和类型
  const promise = new Promise((resolve, reject) => {
    axios[httpType](url, body, {
      cancelToken: options.cancelToken,
      headers: options.headers
    }).then(
      response => {
        resolve(response.data);
      },
      err => {
        if (err) {
          console.error('err', err);
          reject(err);
        }
      }
    );
  });
  if (options.isCancel) {
    return { promise: promise, cancel: options.cancel };
  }
  return promise;
};
/**
 * 转换body数据格式和类型
 */
const setOptionMethodBody = (options, httpType) => {
  const { data } = options;
  if (!data) {
    return data;
  }
  if (options.type === 'form') {
    // 为了正常传递form表单,需要设置charset=UTF-8
    let formData = new URLSearchParams();
    if (options?.noUtf8) {
      formData = new FormData();
    }
    for (const key of Object.keys(data)) {
      if (Object.prototype.toString.call(data[key]) === '[object Array]') {
        data[key].map(item => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, data[key]);
      }
    }
    return formData;
  } else if (options.type === 'json') {
    return JSON.stringify(data);
  } else {
    if (httpType === 'delete') {
      return { data };
    }
    return data;
  }
};
/**
 * 取消请求源
 */
const cancelSource = () => {
  const { token, cancel } = axios.CancelToken.source();
  return { token, cancel };
};
/**
 * 请求
 * @param {string} 请求路径
 * @param {options} 参数
 * options.data 请求参数
 * options.method 请求方式：get/post/patch/put/delete
 * options.type 传参类型 form/json
 * options.isCancel 是否取消请求 true/false 当isCancel为true的时候，会返回一个object,可以直接object.cancel()来取消请求
 */
const http = (url, options = { method: 'get' }) => {
  const Authorization = getLocalStorage('Authorization');
  const curRoles = getLocalStorage('curRoles');
  if (Authorization) {
    axios.defaults.headers.common.Authorization = Authorization;
    // axios.defaults.headers.common['X-UID'] = curRoles.id; // 1  测试用户
    // axios.defaults.headers.common['X-UID'] = 1; // 1  测试用户
  }
  const { token, cancel } = cancelSource();
  options.cancelToken = token;
  options.cancel = cancel;
  if (options.method === 'get' || options.method === 'GET') {
    return get(url, Object.assign({}, { params: options.data || {} }, options));
  } else {
    return optionMethod(url, options); // 数据操作类型（post、put、delete、patch）
  }
};
export default http;
